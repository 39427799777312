import img1 from '../images/common/team1.png';
import img2 from '../images/common/team2.png';
import img3 from '../images/common/team3.png';
import img4 from '../images/common/team4.png';
import img5 from '../images/common/team5.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Phaul',
        position: 'Founder & Leader',
        listsocial: [
        ]
    },
    {
        id: 2,
        img: img2,
        name: '0xRed',
        position: 'Blockchain developer',
        listsocial: [
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Jake',
        position: 'Community Builder',
        listsocial: [
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Saylors',
        position: 'Marketing & Partnership',
        listsocial: [
        ]
    }
]

export default dataTeam;
