import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "About us",
    title: "Who are Cosmic Aliens?",
    desc: "The year is 4444 and the Crypto Galaxy is in turmoil. To save all degens and bros, the united force of aliens have emerged. Cosmic Aliens, a collection of 4444 brave aliens who are passionate and focused on their mission to expedite mass adoption of Cosmos Ecosystem.",
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
              <p>{dataBlock.desc}</p>
              <a
                className="tf-button btn-effect"
                href="https://discord.com/invite/4ujHKQPT"
              >
                <span className="boder-fade"></span>
                <span className="effect">Join Discord</span>
              </a>
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item) => (
                <AboutItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
