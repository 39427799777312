const dataFaq = [
    {
        id: 1,
        title: 'What is Cosmic Aliens?',
        content: 'Cosmic Aliens is a collection of 4,444 Aliens who are passionate and focused on expediting mass adoption of Cosmos blockchain.',
        show: 'show'
    },
    {
        id: 2,
        title: 'When is the release date?',
        content: 'In June',
    },
    {
        id: 3,
        title: 'What is supply?',
        content: '4,444',
    },
    {
        id: 4,
        title: 'Will there be public sale?',
        content: 'Yes. The public sale will take place directly after our whitelist sale.',
    },
    {
        id: 5,
        title: 'What is the mint price?',
        content: 'TBA',
    }
]

export default dataFaq;
