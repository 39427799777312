import bgImg1 from '../images/background/bg-1.png';
import img1 from '../images/common/itemslider-alien.png';

const dataSlider = [
    {
        id: 1,
        title: 'JOIN THE INVADERS BEFORE THE RAID BEGINS',
        desc : 'Cosmic Aliens is a collection of 4,444 Aliens who are passionate and focused on expediting mass adoption of Cosmos blockchain.',
        bgImg: bgImg1,
        img : img1
    },
]

export default dataSlider;
