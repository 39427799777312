import img1 from '../images/common/img1.png'
import img2 from '../images/common/img2.png'
import img3 from '../images/common/img3.png'
import img4 from '../images/common/img4.png'
import img5 from '../images/common/img5.png'
import img6 from '../images/common/img6.png'
import img7 from '../images/common/img7.png'
import img8 from '../images/common/img8.png'
import img9 from '../images/common/img9.png'
import img10 from '../images/common/img10.png'
import img11 from '../images/common/img11.png'
import img12 from '../images/common/img12.png'
import img13 from '../images/common/img13.png'
import img14 from '../images/common/img14.png'
import img15 from '../images/common/img15.png'
import img16 from '../images/common/img16.png'
import img17 from '../images/common/img17.png'
import img18 from '../images/common/img18.png'
import img19 from '../images/common/img19.png'
import img20 from '../images/common/img20.png'
import img21 from '../images/common/img21.png'
import img22 from '../images/common/img22.png'


const dataProject = [
    { id: 1, title: 'ALIEN #01', img: img1, },
    { id: 2, title: 'ALIEN #02', img: img2, },
    { id: 3, title: 'ALIEN #03', img: img3, },
    { id: 4, title: 'ALIEN #04', img: img4, },
    { id: 5, title: 'ALIEN #05', img: img5, },
    { id: 6, title: 'ALIEN #06', img: img6, },
    { id: 7, title: 'ALIEN #07', img: img7, },
    { id: 8, title: 'ALIEN #08', img: img8, },
    { id: 9, title: 'ALIEN #09', img: img9, },
    { id: 10, title: 'ALIEN #10', img: img10, },
    { id: 11, title: 'ALIEN #11', img: img11, },
    { id: 12, title: 'ALIEN #12', img: img12, },
    { id: 13, title: 'ALIEN #13', img: img13, },
    { id: 14, title: 'ALIEN #14', img: img14, },
    { id: 15, title: 'ALIEN #15', img: img15, },
    { id: 16, title: 'ALIEN #16', img: img16, },
    { id: 17, title: 'ALIEN #17', img: img17, },
    { id: 18, title: 'ALIEN #18', img: img18, },
    { id: 19, title: 'ALIEN #19', img: img19, },
    { id: 20, title: 'ALIEN #20', img: img20, },
    { id: 21, title: 'ALIEN #21', img: img21, },
    { id: 22, title: 'ALIEN #22', img: img22, },
]

export default dataProject;
