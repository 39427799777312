const dataAbout = [
    {
        id: 1,
        title: 'Unique, 1/1 Generated',
        desc: 'Algorithmically generated with unique shapes and personalities. No alien is same as other.',
        // class: 'active'
    },
    {
        id: 2,
        title: '100+ traits in 5 categories',
        desc: 'Cosmic Alien PFP is consists of Background, Suit, Species, Accessories, Eyes.',
    },
    {
        id: 3,
        title: 'Usage Right Included',
        desc: 'Ownership and commercial usage rights are given to the holders.',
    },
]

export default dataAbout;
