import img1 from '../images/post/imgpost2.jpg'
import img2 from '../images/post/imgpost3.jpg'
import img3 from '../images/post/imgpost4.jpg'
import img4 from '../images/post/imgpost5.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'nftartwork',
        title: 'Why choose a theme that looks good with WooCommerce',
        time: '20 Jun 2022',
        text: 'We make daily use products more affordable & accessible for a billion Indians by using our...',
    },
    {
        id: 2,
        img : img2,
        category: 'nftartwork',
        title: 'Why choose a theme that looks good with WooCommerce',
        time: '20 Jun 2022',
        text: 'We make daily use products more affordable & accessible for a billion Indians by using our...',
    },
    {
        id: 3,
        img : img3,
        category: 'cryptocurrencies',
        title: 'Why choose a theme that looks good with WooCommerce',
        time: '20 Jun 2022',
        text: 'We make daily use products more affordable & accessible for a billion Indians by using our...',
    },
    {
        id: 4,
        img : img4,
        category: 'digitalartist',
        title: 'Why choose a theme that looks good with WooCommerce',
        time: '20 Jun 2022',
        text: 'We make daily use products more affordable & accessible for a billion Indians by using our...',
    },
]

export default dataBlog;