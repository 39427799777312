const dataRoadmap = [
    {
        id: 1,
        time: 'PHASE 1',
        list: [
            {
                text: 'Release twitter & discord links'
            },
            {
                text: 'Allocate whitelist spots to early supporters'
            },
            {
                text: 'Release roadmap 1.0'
            },
            {
                text: 'Launch website'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'PHASE 2',
        list: [
            {
                text: 'Initiate the mint of GEN 1 Cosmic Aliens PFP'
            },
            {
                text: 'Listing on marketplace (Stargaze marketplace)'
            },
            {
                text: 'Holder Verification'
            },
            {
                text: 'Implement Sales bot'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'PHASE 3',
        list: [
            {
                text: 'DAO formation with royalty sharing'
            },
            {
                text: 'Exclusive alpha channels for holders'
            },
            {
                text: 'Exclusive giveaways + WL Opportunities'
            },
            {
                text: 'Release LORE & Showcase GEN 2'
            }
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'PHASE 4',
        list: [
            {
                text: 'Initiate the mint of GEN 2'
            },
            {
                text: 'Exclusive Merchandise'
            },
            {
                text: 'Release roadmap 2.0'
            },
        ],
        positon: 'right'
    }
]

export default dataRoadmap;
